import actionRenderer from './action_renderer.mjs'

export default function renderActions(data, type, row) {
    /*
    procedimento:
    - primeiros 5 aparecendo em xg
    - dropdown: (- se tiver 5 ou +, visível em displays grandes)
        - primeiros 5 ocultos em xg
        - o resto
     */

    let desktopActions,
        extraDesktopActions

    let posUltimaAcao = 0

    if (data.length <= 5) {
        desktopActions = data.slice(0, 5)
        extraDesktopActions = []
    } else {
        // é possível que existam acoes que não devem ser exibidas (invalidas)
        // neste caso, estas acoes não devem ser consideradas ao exibir as primeiras 4 ações e o dropdown de + ações
        // para evitar que no caso de alguma das 4 primeiras acões ser invalida,
        // serem exibidas menos que 4 ações iniciais junto ao dropdown
        let countAcoesValidas = 0
        // garantir que vai exibir nas 4 primeiras ações apenas ações válidas (visiveis)
        data.forEach(acao => {
            if (acao.url !== undefined && acao.url !== null && acao.url !== '' && acao.class !== undefined && acao.class !== null && acao.label !== undefined && acao.label !== null)
                countAcoesValidas++;
            if (countAcoesValidas < 5)
                posUltimaAcao++;
        })
        desktopActions = data.slice(0, posUltimaAcao)
        extraDesktopActions = data.slice(posUltimaAcao)
    }
    const dropdownClass = data.length < 5 || posUltimaAcao === data.length ? 'd-md-none' : ''
    const mobileActions = data


    //add tooltip
    data.forEach(acao => {
        if (acao !== '') {
            let strTooltip = ''
            if (acao.title !== '' && acao.title !== 'undefined') {
                strTooltip = 'onmouseover="return infraTooltipMostrar(\'' + acao.title + '\',\'\');" onmouseout="return infraTooltipOcultar();"';
            }
            acao.strTooltip = strTooltip
        }
    })

    const htmlDesktopActions = desktopActions
        .map(acao => actionRenderer(acao).iconButton())
        .join('')

    const htmlDropActions = extraDesktopActions
        .map(acao => actionRenderer(acao).dropdownItem(true))
        .join('')

    const htmlMobileActions = mobileActions
        .map(acao => actionRenderer(acao).dropdownItem(false))
        .join('')

    const extraDesktopActionsHtml = !extraDesktopActions.length ? '' : `
        <a class="acoes-trigger-extra" title="Mais ações" data-toggle="dropdown" aria-haspopup="true" href="#">
            <i class="material-icons text-primary">more_vert</i> 
        </a>`

    return `
        <span class="container-acoes-table">
            <span class="container-acoes-table__desktop">
                ${htmlDesktopActions}
            </span>
            <span class="container-acoes-table__extra dropleft ${dropdownClass}">
                <a class="acoes-trigger-mobile shadow-sm bg-primary" title="Mais ações" data-toggle="dropdown" aria-haspopup="true" href="#">
                    <i class="material-icons icon-aligned text-white">more_horiz</i>
                </a>               
                
                ${extraDesktopActionsHtml}
                
                <div role="menu" class="dropdown-menu" style="position:relative">
                    ${htmlDropActions}
                    ${htmlMobileActions}
                </div>
            </span>
        </span>
    `
}