import _ from 'lodash'
import ACTION_TYPES from './action_types.mjs'

const isValid = (acao) => acao.url !== undefined && acao.url !== null && acao.url !== '' && acao.class !== undefined && acao.class !== null && acao.label !== undefined && acao.label !== null

function actionRenderer(acao) {
    let params;

    if (!isValid(acao)) {
        return {
            iconButton: () => '',
            dropdownItem: () => ''
        }
    }

    const attrsToHtml = attrs => _.map(attrs, (v, k) => `${k}="${v}"`).join(' ')
    const iconButton = (acao, attrs) => {
        const htmlAttrs = attrsToHtml(attrs)

        return `<a class="${acao.class}"
            ${acao.strTooltip}
            ${htmlAttrs}>${icone}</a>`
    }
    const dropdownItem = (isExtra, acao, attrs) => {
        const htmlAttrs = attrsToHtml(attrs)
        const addClass = isExtra ? 'action-dropdown-item-extra' : 'action-dropdown-item-mobile'
        return `<a role="presentation"
            class="${acao.class} dropdown-item pl-2 ${addClass}"
            ${htmlAttrs}
            ${strTooltip}>${icone}${acao.label}</a>`
    }

    // foi trocado o === abaixo por == porque no servidor (DataTableINT) � feito utf8_encode de true, que resulta em 1. Quando o c�digo for isolado, isso poder� ser revertido
    const icone = (acao.MD == true)
        ? '<i class="material-icons text-primary">' + acao.icon + '</i>'
        : '<img src="' + acao.icon + '" alt="' + acao.label + '" class="infraImg">'

    const strTooltip = acao.strTooltip

    switch (acao.tipo) {
        case ACTION_TYPES.POPUP:
            params = [acao, {
                href: `javascript: exibirSubFrm('${acao.url}', ${acao.width}, ${acao.height})`
            }]
            return {
                iconButton: () => iconButton(...params),
                dropdownItem: (isExtra) => dropdownItem(isExtra, ...params)
            }

        case ACTION_TYPES.ICONE:
            params = [acao, {href: "javascript:void(0)"}]
            return {
                iconButton: () => iconButton(...params),
                dropdownItem: (isExtra) => dropdownItem(isExtra, ...params)
            }
        case ACTION_TYPES.ONCLICK:
            params = [acao, {
                href: "javascript:void(0)",
                onclick: `executarAcao('${acao.acao}', '${acao.url}', this.closest('tr'));`
            }]
            return {
                iconButton: () => iconButton(...params),
                dropdownItem: (isExtra) => dropdownItem(isExtra, ...params)
            }
        case ACTION_TYPES.NORMAL:
            params = [acao, {href: acao.url}]
            return {
                iconButton: () => iconButton(...params),
                dropdownItem: (isExtra) => dropdownItem(isExtra, ...params)
            }
        case ACTION_TYPES.AJAX:
            let htmlParams = {
                href: "javascript:void(0)",
                id: acao.id,
                name: acao.name,
                urlajax: acao.url
            }
            $.each(acao.data, (key, val) => {
                htmlParams[`data-${key}`] = val
            });
            $.each(acao.data, (key, val) => {
                htmlParams[key] = val
            });

            params = [acao, htmlParams]

            return {
                iconButton: () => iconButton(...params),
                dropdownItem: (isExtra) => dropdownItem(isExtra, ...params)
            }
        case ACTION_TYPES.NOVAJANELA:
            params = [acao, {
                target: "_blank",
                href: acao.url
            }]

            return {
                iconButton: () => iconButton(...params),
                dropdownItem: (isExtra) => dropdownItem(isExtra, ...params)
            }
        default:
            params = [acao, {href: acao.url}]
            return {
                iconButton: () => iconButton(...params),
                dropdownItem: (isExtra) => dropdownItem(isExtra, ...params)
            }
    }
}
export default actionRenderer