import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import * as JSZip from 'jszip'
import 'datatables.net-bs4'
import 'datatables.net-responsive-bs4'
import 'datatables.net-select-bs4'
import 'datatables.net-rowgroup-bs4'
import 'datatables.net-buttons-bs4'
import DataTableHelper from './dataTableHelper'
import renderActions from './datatables/actions.mjs'
import toast from '#eproc/toast.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

window.JSZip = JSZip

require('datatables.net-buttons/js/buttons.html5')(window, $)
require('datatables.net-buttons/js/buttons.print')(window, $)
require('datatables.net-buttons/js/buttons.colVis.js')(window, $)

window.DataTableHelper = DataTableHelper;

(function($) {
    const language = {
        'sEmptyTable': 'Nenhum registro encontrado',
        'sInfo': 'Mostrando de _START_ até _END_ de _TOTAL_ registros',
        'sInfoEmpty': 'Nenhum registro encontrado.',
        'sInfoFiltered': '(Filtrados de _MAX_ registros)',
        'sInfoPostFix': '',
        'sInfoThousands': '.',
        'thousands': '.',
        'decimal': ',',
        'sLengthMenu': '_MENU_ resultados por página',
        'sLoadingRecords': 'Carregando...',
        'sProcessing': 'Processando...',
        'sZeroRecords': 'Nenhum registro encontrado',
        'sSearch': 'Pesquisar',
        'oPaginate': {
            'sNext': 'Próximo',
            'sPrevious': 'Anterior',
            'sFirst': 'Primeiro',
            'sLast': 'Último',
        },
        'oAria': {
            'sSortAscending': ': Ordenar colunas de forma ascendente',
            'sSortDescending': ': Ordenar colunas de forma descendente',
        },
        'select': {
            'rows': {
                _: 'Selecionado %d linhas',
                0: 'Clique no checkbox para selecionar',
                1: 'Selecionado 1 linha',
            },
        },
        buttons: {
            print: 'Imprimir',
            copy: 'Copiar',
            colvis: 'Colunas',
            selectAll: 'Marcar Todos',
            selectNone: 'Desmarcar Todos',
        },
    }

    $.fn.dataTable.getDataByButton = function(btn) {
        let tr = btn.closest('tr')
        let id = tr.closest('table').attr('id')
        let data = {}
        if ($.fn.dataTable.isDataTable('#' + id)) {
            data = $('#' + id).DataTable().row(tr).data()
        }
        return data
    }

    $.fn.dataTable.checkAll = function(checkAll) {
        let id = checkAll.parents('table').prop('id')
        let rows = $('#' + id).DataTable().rows()
        let deselect = []
        var variavelGlobal = $.fn.dataTable.variavelGlobalIdsSelecionados(id)
        if (checkAll.is(':checked')) {
            rows.select()
            $.each(rows.ids().toArray(), function(key, id) {
                variavelGlobal.push(id)
            })
        } else {
            rows.deselect()
            deselect = rows.ids().toArray()
        }
        for (let key = variavelGlobal.length - 1; key >= 0; key--) {
            let value = variavelGlobal[key]
            if (value.trim() === '' || variavelGlobal.indexOf(value) !== key || deselect.indexOf(value) !== -1) {
                variavelGlobal.splice(key, 1)
            }
        }
    }

    $.fn.dataTable.variavelGlobalIdsSelecionados = function(id) {
        let nome = 'idsSelecionados' + id
        if (window[nome] === undefined) {
            window[nome] = []
        }
        return window[nome]
    }

    $.fn.dataTable.renderCustomColumnLinkProcesso = function(linkField, row, numProcessoField) {
        return '<a href="' + linkField + '">' + infraFormatarProcessoTrf4(row[numProcessoField]) + '</a>'
    }

    $.fn.dataTable.renderCustomColumnNumProcessoFormatado = (data, type, row, meta) => {
        const columnName = meta.settings.aoColumns[meta.col].data
        return infraFormatarProcessoTrf4(row[columnName])
    }

    $.fn.dataTable.renderCustomColumnSequencial = (data, type, row, meta) => {
        return meta.settings._iDisplayStart + 1 + meta.row
    }

    $.fn.dataTable.montarData = function(d, form) {
        form.find('input, select').each(function() {
            let name = $(this).attr('name')
            if (name !== undefined && $(this).val() !== undefined) {
                if ($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                    if ($(this).is(':checked')) {
                        d[name] = $(this).val()
                    }
                } else {
                    d[name] = $(this).val()
                }
            }
        })
    }

    $.fn.dataTable.render.linksAcoes = function() {
        return renderActions
    }

    $.extend(true, $.fn.dataTable.defaults, {
        'processing': true, //Habilita e desabilita mensagem de processando na paginação e filtros
        'searching': false, //Habilita e desabilita os campos de filtro
        'stateSave': true, //Mantém a página da paginação
        //https://datatables.net/reference/option/dom
        'dom': 'r' +
            '<\'row\'<\'col-sm-12 col-md-12\'B>>' +
            '<\'row\'<\'col-sm-12 col-md-6\'l><\'col-sm-12 col-md-6\'p>>' +
            '<\'row\'<\'col-sm-12\'t>>' +
            '<\'row\'<\'col-sm-12 col-md-5\'i><\'col-sm-12 col-md-7\'p>>',
        'language': language,
        'buttons': {
            dom: {
                container: {
                    className: 'text-right container-acoes-bloco',
                },
                button: {
                    tag: 'a',
                    className: 'd-flex  ml-2 align-items-center btn-acoes-bloco',
                },
                buttonLiner: {
                    tag: null,
                },
                collection: {
                    className: 'dropdown-menu dropdown-menu-right mr-n4',
                },
            },
        },
    })

    /**
     *
     * @param opts
     * @returns {*|Window.jQuery}
     */
    $.fn.dataTablePadrao = function(opts) {
        const defaultOrderableColumn = opts.hideColDetalhes === true ? 1 : 2
        opts.order = typeof opts.order !== 'undefined' ? opts.order : [[defaultOrderableColumn, 'asc']]
        opts.serverSide = typeof opts.serverSide !== 'undefined' ? opts.serverSide : true
        opts.paging = typeof opts.paging !== 'undefined' ? opts.paging : true
        opts.dom = typeof opts.dom !== 'undefined' ? opts.dom : $.fn.dataTable.defaults.dom
        opts.pageLength = typeof opts.pageLength !== 'undefined' ? opts.pageLength : $.fn.dataTable.defaults.pageLength

        if (opts.hideColDetalhes) {
            opts.responsive = {
                details: {
                    display: $.fn.dataTable.Responsive.display.childRowImmediate,
                    type: 'none',
                    target: '',
                },
            }
        }

        opts.columnDefs = opts.columnDefs || []

        if (opts.hideColDetalhes !== true) {
            opts.columnDefs.push({
                targets: 0,
                className: 'control not-export-col',
                orderable: false,
            })
            if (opts.columns[0]['data'] !== 'col_detalhes' && opts.columns[1]['data'] !== 'col_detalhes') {
                opts.columns.unshift({
                    'data': 'col_detalhes',
                })
            }
        }

        opts.buttons = {
            dom: $.fn.dataTable.defaults.buttons.dom,
            buttons: typeof opts.buttons !== 'undefined' ? opts.buttons : [
                'excel',
                'csv',
                'pdfHtml5',
                'print',
                'copy',
                'colvis',
            ],
        }
        if (opts.select) {
            opts.select = {style: 'multi', selector: 'td:nth-child(2)'}
            if (opts.columns[0]['data'] !== 'col_checkbox') {
                opts.columns.unshift({
                    'data': 'col_checkbox',
                })
            }
            opts.columnDefs.push({
                title: '<input type="checkbox" id="checkAll" name="checkAll">',
                targets: 1,
                className: 'dtr-control select-checkbox text-center not-export-col',
                orderable: false,
            })
            //opts.buttons.buttons.push(['selectAll', 'selectNone']);
        }

        if (opts.hideColDetalhes !== true) {
            if (opts.actions && opts.columns[opts.columns.length - 1]['data'] !== 'col_acoes') {
                opts.columns.push({
                    'data': 'col_acoes',
                    'title': 'Ações',
                    'orderable': false,
                    'className': 'dtr-control acoes-table not-export-col',
                    'responsivePriority': 1000,
                    'render': $.fn.dataTable.render.linksAcoes(),
                })
            }
        }

        if (!opts.ajax.hasOwnProperty('error')) {
            opts.ajax.error = function(jqXHR, textStatus, errorThrown) {
                var erros = $($.parseXML(jqXHR.responseText)).find('erros')
                var arrErros = []
                erros.each(function(index) {
                    arrErros.push($(this).find('erro').attr('descricao').infraReplaceAll('\\n', '<br>'))
                })
                infraOcultarAviso()
                eproc.toast.error(arrErros.join('<br>'))
            }
        }
        return $(this).DataTable(opts)
    }

    $.fn.dataTable.exportarTodasPaginas = function(e, dt, button, config) {
        const
            that     = this,
            oldStart = dt.settings()[0]._iDisplayStart

        dt.one('preXhr', function(e, s, data) {
            data.start = 0
            data.length = 2147483647

            dt.one('preDraw', function(e, settings) {
                const
                    btnClass  = button[0].className,
                    actionMap = {
                        'copy': 'copyHtml5',
                        'excel': 'excelHtml5',
                        'csv': 'csvHtml5',
                        'pdf': 'pdfHtml5',
                    },
                    match     = Object.keys(actionMap).find(key => btnClass.indexOf(`buttons-${key}`) >= 0)

                if (match) {
                    const action = actionMap[match]
                    $.fn.dataTable.ext.buttons[action].action.call(that, e, dt, button, config)

                    if (match === 'copy') {
                        toast.success('Conteúdo da tabela copiado para a área de transferência.')
                    }
                }

                dt.one('preXhr', function(e, s, data) {
                    settings._iDisplayStart = oldStart
                    data.start = oldStart
                })

                setTimeout(dt.ajax.reload, 0)
                return false
            })
        })
        dt.ajax.reload()
    }

    $('body').on('shown.bs.tab', 'a[data-toggle="pill"]', function() {
        $.fn.dataTable.tables({visible: true, api: true}).columns.adjust()
    })

    /**
     * @deprecated  - Utilizar a função dataTablePadrao (https://git.trf4.jus.br/tj_rs/eproc2/-/wikis/Data-Table)
     */
    $.fn.dataTableInit = function(ajax, columns, boColCheck = false, stColAcao = '', stCriterioExibicao = '') {
        $.extend(true, $.fn.dataTable.defaults, {
            responsive: {
                details: {
                    type: 'column',
                    target: -1,
                },
            },
            columnDefs: [
                {
                    targets: -1,
                    className: 'control',
                    orderable: false,
                },
            ],
            'serverSide': true, //Habilita e desabilita a busca de dados no servidor
            'drawCallback': function(settings) {
                $('.btnDesativar').on('click', function() {
                    dataTablesConfirmarDesativar($(this))
                })
                $('.btnReativar').on('click', function() {
                    dataTablesConfirmarReativar($(this))
                })
            },
        })

        var columnCheckbox = {
            'data': 'col_checkbox',
            'orderable': false,
            'className': 'select-checkbox',
        }

        var buttons = []
        var columnAcoes = {
            'data': 'col_acoes',
            'title': stColAcao,
            'orderable': false,
            'className': 'acoes-table',
            'responsivePriority': 1000,
            'render': $.fn.dataTable.render.linksAcoes(),
        }

        var select = false
        if (boColCheck) {
            columns.unshift(columnCheckbox)
            select = {
                style: 'multi',
                selector: 'td:first-child',
            }

            buttons = {
                dom: {
                    container: {
                        className: 'dt-buttons btn-group btn-group-sm',
                    },
                    button: {
                        className: 'btn btn-primary',
                    },
                },
                buttons: [
                    {
                        extend: 'selectAll',
                        text: 'Marcar Todos',
                    },
                    {
                        extend: 'selectNone',
                        text: 'Desmarcar Todos',
                    },
                ],
            }
        }
        if (stColAcao.trim() !== '') {
            columns.push(columnAcoes)
        }

        var infraDataTable = $(this).DataTable({
            'ajax': ajax,
            'order': [[1, 'asc']],
            'select': select,
            'columns': columns,
            'buttons': buttons,
            responsive: {
                details: {
                    type: 'column',
                    target: -1,
                },
            },
            columnDefs: [
                {
                    targets: -1,
                    className: 'control',
                    orderable: false,
                },
            ],
            'processing': true, //Habilita e desabilita mensagem de processando na paginação e filtros
            'serverSide': true, //Habilita e desabilita a busca de dados no servidor
            'searching': false, //Habilita e desabilita os campos de filtro
            'stateSave': true, //Mantêm a página da paginação
            //https://datatables.net/reference/option/dom
            'dom': '<\'row\'<\'col-sm-12\'r>>' +
                '<\'row\'<\'col-sm-12 col-md-6\'l><\'col-sm-12 col-md-6\'p>>' +
                '<\'row\'<\'col-sm-12 col-md-12\'B>>' +
                '<\'row\'<\'col-sm-12\'t>>' +
                '<\'row\'<\'col-sm-12 col-md-5\'i><\'col-sm-12 col-md-7\'p>>',
            'language': language,
            'drawCallback': function(settings) {
                $('.btnDesativar').on('click', function() {
                    dataTablesConfirmarDesativar($(this))
                })
                $('.btnReativar').on('click', function() {
                    dataTablesConfirmarReativar($(this))
                })
            },
        })

        if (stCriterioExibicao.trim() !== '') {
            var divCriterio = ''
            divCriterio += '<div class="card" id="criteriosDeExibicao" style="margin: 10px 0;">'
            divCriterio += '<h5 class="card-header">' + stCriterioExibicao.trim() + '</h5>'
            divCriterio += '<div class="card-body">'
            divCriterio += '<div class="form-group">'
            $.each(columns, function(key, column) {
                if (column.title != undefined) {
                    if (column.id != undefined) {
                        var id = column.id
                    } else {
                        var id = column.data
                    }

                    divCriterio += '<div class="form-check form-check-inline">'
                    divCriterio += '<label id="lbl' + key + '" class="form-check-label">'
                    divCriterio += '<input type="checkbox" name="ckb' + id + '" id="ckb' + id + '" value="' + key
                        + '" class="form-check-input">' + column.title
                    divCriterio += '</label>'
                    divCriterio += '</div>'
                }
            })
            divCriterio += '</div>'
            divCriterio += '</div>'
            divCriterio += '</div>'
            $(divCriterio).insertAfter($(this))
        }
        return infraDataTable
    }

    // permite que colunas de data sejam formatadas com tipo correto no excel, para que seja possível ordenar corretamente
    // baseado em: https://datatables.net/forums/discussion/50616/what-is-the-correct-format-to-export-dates-with-html5-to-excel
    $.fn.dataTable.customizeExcel = function(xlsx, config, api) {
        let sheet = xlsx.xl.worksheets['sheet1.xml']
        let styles = xlsx.xl['styles.xml']

        let values = $('numFmts numFmt', styles).map(function() {
            return this.getAttribute('numFmtId') || -Infinity
        }).toArray()

        let numFmtId = Math.max.apply(Math, values) + 1
        let nFmtDateTime = '<numFmt numFmtId="' + numFmtId + '" formatCode="dd/mm/yyyy hh:mm:ss"/>'
        let nFmtDate = '<numFmt numFmtId="' + (numFmtId + 1) + '" formatCode="dd/mm/yyyy"/>'

        let el = $('numFmts', styles)
        el.append(nFmtDateTime).append(nFmtDate).attr('count', parseInt(el.attr('count')) + 2)

        let styleDateTime = '<xf numFmtId="' + numFmtId
            + '" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyNumberFormat="1"/>'
        let styleDate = '<xf numFmtId="' + (numFmtId + 1)
            + '" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyNumberFormat="1"/>'
        el = $('cellXfs', styles)
        el.append(styleDateTime).append(styleDate).attr('count', parseInt(el.attr('count')) + 2)
        let styleDateTimeIdx = $('xf', el).length - 2
        let styleDateIdx = styleDateTimeIdx + 1

        let arrColunas = []
        let indexColuna = 0
        let indexLinhaTitulos = 0
        // obter indice das colunas realmente exibidas no relatorio
        // se o titulo da planilha for configurado ele estara na primeira linha,
        // e o titulo das colunas fica na segunda linha, caso contrario o titulo das colunas fica na primeira linha
        if ($('row:eq(0) c', sheet).length <= 1) {
            indexLinhaTitulos = 1
        }
        $('row:eq(' + indexLinhaTitulos + ') c', sheet).each(function() {
            arrColunas[$('is t', this).text()] = indexColuna++
        })

        // obtem colunas onde a data deve ser formatada, e converte o indice para excel para aplicar estilo
        let arrColunasData = []
        api.columns('.colFormatarEstiloDateTime').every(function() {
            let excelIndex = ''
            let colHeader = this.header().innerHTML
            let indexCharMax = 'Z'.charCodeAt(0)
            let indexChar = arrColunas[colHeader] + 'A'.charCodeAt(0)
            if (indexChar > indexCharMax) {
                let indexPrimeiroChar = -1
                while (indexChar > indexCharMax) {
                    indexChar -= 26
                    indexPrimeiroChar++
                }
                excelIndex += String.fromCharCode(indexPrimeiroChar + 'A'.charCodeAt(0))
            }
            excelIndex += String.fromCharCode(indexChar)
            arrColunasData.push(excelIndex)
        })

        // percorre todas células das colunas definidas com classe de data e adiciona estilo caso realmente contenha uma data
        for (let i = 0; i < arrColunasData.length; i++) {
            $('row:gt(0) c[r^="' + arrColunasData[i] + '"]', sheet).each(function() {
                let text = $('is t', this).text()
                let matchesDateTime = text.match(/^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/)
                let matchesDate = text.match(/^(\d{2})\/(\d{2})\/(\d{4})$/)
                if (matchesDateTime || matchesDate) {
                    let p = text.split(' ')
                    let date = p[0]
                    let pDate = date.split('/')
                    let timestamp = Math.round(25569 + (Date.parse(pDate[2] + '-' + pDate[1] + '-' + pDate[0]) / (86400
                        * 1000))) // parte da data
                    if (matchesDateTime) {
                        let time = p[1]
                        let pTime = time.split(':')
                        timestamp += pTime[0] / 24 + pTime[1] / 1440 + pTime[2] / 86400 //parte da hora
                    }

                    // altera o elemento na planilha para que tenha a mesma estrutura de um número e não de uma string
                    // (conforme gerado pelo método de exportação do datatables)
                    $(this).attr('t', 'n')
                    let tempNode = sheet.createElement('v')
                    tempNode.appendChild(sheet.createTextNode(timestamp))
                    $('is', this).remove()
                    $(this).append(tempNode)
                    $(this).attr('s', (matchesDateTime ? styleDateTimeIdx : styleDateIdx))
                }
            })
        }
    }
})(jQuery)
